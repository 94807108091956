{
  "name": "esri-leaflet-renderers",
  "description": "esri-leaflet plugin for rendering",
  "version": "2.0.6",
  "author": "Rachel Nehmer <rnehmer@esri.com>",
  "bugs": {
    "url": "https://github.com/esri/esri-leaflet-renderers/issues"
  },
  "contributors": [
    "Rachel Nehmer <rnehmer@esri.com>",
    "John Gravois <jgravois@esri.com>"
  ],
  "dependencies": {
    "esri-leaflet": "^2.0.0",
    "leaflet": "^1.0.0-rc.3",
    "leaflet-shape-markers": "^1.0.4"
  },
  "devDependencies": {
    "babelify": "^6.1.3",
    "chai": "3.5.0",
    "gh-release": "^2.0.0",
    "http-server": "^0.8.5",
    "isparta": "^4.0.0",
    "istanbul": "^0.4.2",
    "karma": "^1.3.0",
    "karma-chai-sinon": "^0.1.3",
    "karma-coverage": "^1.1.1",
    "karma-mocha": "^1.3.0",
    "karma-mocha-reporter": "^2.2.1",
    "karma-phantomjs-launcher": "^1.0.2",
    "karma-sourcemap-loader": "^0.3.5",
    "mkdirp": "^0.5.1",
    "mocha": "^3.1.0",
    "phantomjs-prebuilt": "^2.0.0",
    "rollup": "^0.25.4",
    "rollup-plugin-json": "^2.0.0",
    "rollup-plugin-node-resolve": "^1.4.0",
    "rollup-plugin-uglify": "^0.3.1",
    "semistandard": "^10.0.0",
    "sinon": "^1.11.1",
    "sinon-chai": "2.8.0",
    "snazzy": "^5.0.0",
    "uglify-js": "^2.6.1",
    "watch": "^0.17.1"
  },
  "homepage": "http://esri.github.io/esri-leaflet",
  "jsnext:main": "src/EsriLeafletRenderers.js",
  "jspm": {
    "registry": "npm",
    "format": "es6",
    "main": "src/EsriLeafletRenderers.js"
  },
  "keywords": [
    "arcgis",
    "esri",
    "esri leaflet",
    "gis",
    "leaflet plugin",
    "mapping",
    "renderers",
    "symbology"
  ],
  "license": "Apache-2.0",
  "main": "dist/esri-leaflet-renderers-debug.js",
  "module": "src/EsriLeafletRenderers.js",
  "browser": "dist/esri-leaflet-renderers-debug.js",
  "readmeFilename": "README.md",
  "repository": {
    "type": "git",
    "url": "git@github.com:Esri/esri-leaflet-renderers.git"
  },
  "scripts": {
    "prebuild": "mkdirp dist",
    "build": "rollup -c profiles/debug.js & rollup -c profiles/production.js",
    "lint": "semistandard src/**/*.js | snazzy",
    "prepublish": "npm run build",
    "pretest": "npm run build",
    "release": "./scripts/release.sh",
    "start": "watch 'npm run build' src & http-server -p 5000 -c-1 -o",
    "test": "npm run lint && karma start"
  }
}
